import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import './JobApplicantReports.css';
import { ClientUserContext } from '../context/ClientUserContext';
import { AgGridReact } from 'ag-grid-react';
import { AgChartsReact } from 'ag-charts-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { PiFileXls } from "react-icons/pi";
import { PiFileCsv } from "react-icons/pi";
import { PiFilePdfLight } from "react-icons/pi";
import { PiExport } from "react-icons/pi";
//import { calculatePercentile } from '../utils/mathUtils';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FaRegFilePdf } from "react-icons/fa6";
import { BsArrowRepeat, BsFileEarmarkExcel } from "react-icons/bs";
import { BsFiletypeCsv } from "react-icons/bs";
import { BsFiletypeXls } from "react-icons/bs";

const JobApplicantReports = () => {
    const { clientJobId } = useParams();
    const { clientUser } = useContext(ClientUserContext);
    const [applicantReports, setApplicantReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [job, setJob] = useState(); // Store job name
    const [jobName, setJobName] = useState('');
    const [evaluationCriteriaMap, setEvaluationCriteriaMap] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [weightages, setWeightages] = useState({});
    const [showWeightageTable, setShowWeightageTable] = useState(false);
    const [tempWeightages, setTempWeightages] = useState({ ...weightages });
    const [refreshing, setRefreshing] = useState(false);

    const gridRef = useRef(null);  // Reference for accessing the grid API
    //const chartContainerRef = useRef(null); // Reference for chart container
    useEffect(() => {
        const fetchApplicantReports = async () => {
            try {
                const jobDetailsResponse = await fetch(getApiUrl(`/api/client-jobs/${clientJobId}`));
                const jobDetailsData = await jobDetailsResponse.json();
                setJobName(jobDetailsData.job_title);
                setJob(jobDetailsData);

                const response = await fetch(getApiUrl(`/api/scores/client-jobs/${clientJobId}/candidate-scores`));
                const data = await response.json();

                if (!data || !data.report || data.report.length === 0) {
                    setApplicantReports([]);
                    setLoading(false);
                    return;
                }
                setApplicantReports(data.report);

                // Create a map of evaluation criteria for each interview
                const criteriaMap = {};
                data.report.forEach(applicant => {
                    Object.keys(applicant.interviews).forEach(interviewName => {
                        if (!criteriaMap[interviewName]) {
                            criteriaMap[interviewName] = new Set();
                        }
                        const interviewScores = applicant.interviews[interviewName];
                        Object.keys(interviewScores).forEach(criteria => {
                            criteriaMap[interviewName].add(criteria);
                        });
                    });
                });

                const finalCriteriaMap = {};
                Object.keys(criteriaMap).forEach(interview => {
                    finalCriteriaMap[interview] = Array.from(criteriaMap[interview]);
                });
                setEvaluationCriteriaMap(finalCriteriaMap);

                // Load weightages from localStorage if available, otherwise initialize with default values
                const savedWeightages = JSON.parse(localStorage.getItem(`weightages_${clientJobId}`)) || {};
                const initialWeightages = {};
                Object.keys(finalCriteriaMap).forEach(interviewName => {
                    initialWeightages[interviewName] = {};
                    finalCriteriaMap[interviewName].forEach(criteria => {
                        initialWeightages[interviewName][criteria] = savedWeightages[interviewName]?.[criteria] ?? 1;
                    });
                });
                setWeightages(initialWeightages);
                setTempWeightages(initialWeightages);

            } catch (error) {
                console.error('Error fetching applicant reports:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchApplicantReports();
    }, [clientJobId]);

    const refreshApplicantReports = async () => {
        setRefreshing(true)
        try {
            const response = await fetch(getApiUrl(`/api/scores/client-jobs/${clientJobId}/candidate-scores`));
            const data = await response.json();

            if (!data || !data.report || data.report.length === 0) {
                setApplicantReports([]);
                return;
            }
            setApplicantReports(data.report);

        } catch (error) {
            console.error('Error refreshing applicant reports:', error);
        } finally {
            setRefreshing(false)
        }
    };


    const handleWeightageChange = (interviewName, criteria, value) => {
        if (value === '' || isNaN(value)) {
            setTempWeightages((prev) => ({
                ...prev,
                [interviewName]: {
                    ...prev[interviewName],
                    [criteria]: value,
                }
            }));
        } else {
            const numericValue = parseFloat(value);
            setTempWeightages((prev) => ({
                ...prev,
                [interviewName]: {
                    ...prev[interviewName],
                    [criteria]: numericValue,
                }
            }));

            setWeightages((prev) => ({
                ...prev,
                [interviewName]: {
                    ...prev[interviewName],
                    [criteria]: numericValue,
                }
            }));
        }
    };

    const saveWeightages = () => {
        localStorage.setItem(`weightages_${clientJobId}`, JSON.stringify(tempWeightages));
        setWeightages(tempWeightages);
        alert('Weightages saved successfully!');
    };

    const downloadExcel = () => {
        const wsData = [];

        const headers = ['Candidate', 'Total Score']; // Add the Total Score header
        Object.keys(evaluationCriteriaMap).forEach(interviewName => {
            evaluationCriteriaMap[interviewName].forEach(criteria => {
                headers.push(`${interviewName} - ${criteria}`);
            });
        });
        wsData.push(headers);

        applicantReports.forEach(applicant => {
            const row = [applicant.userName];
            let totalWeightedScore = 0; // Total weighted score

            Object.keys(evaluationCriteriaMap).forEach(interviewName => {
                evaluationCriteriaMap[interviewName].forEach(criteria => {
                    const score = parseFloat(applicant.interviews[interviewName]?.[criteria] ?? 0);
                    const weightage = weightages[interviewName]?.[criteria] ?? 1;
                    const weightedScore = score * weightage;
                    row.push(score.toFixed(1));
                    if (!isNaN(weightedScore)) {
                        totalWeightedScore += weightedScore;
                    }
                });
            });

            row.splice(1, 0, totalWeightedScore.toFixed(1));
            wsData.push(row);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Reports');

        XLSX.writeFile(wb, `ApplicantReports_${jobName}.xlsx`);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ['Candidate', 'Total Score'];
        const tableRows = [];

        Object.keys(evaluationCriteriaMap).forEach(interviewName => {
            evaluationCriteriaMap[interviewName].forEach(criteria => {
                tableColumn.push(`${interviewName} - ${criteria}`);
            });
        });

        applicantReports.forEach(applicant => {
            const rowData = [applicant.userName];
            let totalScore = 0;

            Object.keys(evaluationCriteriaMap).forEach(interviewName => {
                evaluationCriteriaMap[interviewName].forEach(criteria => {
                    const score = parseFloat(applicant.interviews[interviewName]?.[criteria] ?? 0);
                    const weightage = weightages[interviewName]?.[criteria] ?? 1;
                    const weightedScore = score * weightage;
                    rowData.push(score.toFixed(1));
                    if (!isNaN(weightedScore)) {
                        totalScore += weightedScore;
                    }
                });
            });

            rowData.splice(1, 0, totalScore.toFixed(1)); // Insert total score after the candidate name
            tableRows.push(rowData);
        });

        doc.text(`Applicant Reports: ${jobName}`, 14, 16);
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 20,
        });
        doc.save(`ApplicantReports_${jobName}.pdf`);
    };

    // Generate columns based on the evaluation criteria, without additional API calls
    const generateColumns = () => {
        const columns = [
            {
                headerName: 'Candidate',
                field: 'userName',
                cellRenderer: (params) => {
                    console.log('params.data:', params.data); // Debug log
                    return (
                        <Link
                            className="candidate-link"
                            to={`/jobs/all-scores/${clientJobId}/${params.data.userId}?candidateName=${encodeURIComponent(params.data.userName)}`}
                        >
                            {params.data.userName}
                        </Link>
                    );
                },
                sortable: true,
                filter: true,
            },
        ];

        // Add a Total Score column that sums up all relevant numeric columns
        // Add a Total Score column that sums up all relevant numeric columns
        columns.push({
            headerName: 'Total Score',
            field: 'totalScore',
            valueGetter: (params) => {
                let totalScore = 0;
                Object.keys(evaluationCriteriaMap).forEach(interviewName => {
                    evaluationCriteriaMap[interviewName].forEach(criteria => {
                        const score = parseFloat(params.data[`${interviewName}_${criteria}`] ?? 0);
                        const weightage = weightages[interviewName]?.[criteria];
                        console.log(weightage);
                        if (weightage > 0 && !isNaN(score)) {
                            totalScore += score * weightage;
                        }

                    });
                });
                return parseFloat(totalScore.toFixed(1));
            },
            type: 'numericColumn',
            sortable: true,
            filter: true,
        });


        Object.keys(evaluationCriteriaMap).forEach((interviewName) => {
            const interviewColumns = evaluationCriteriaMap[interviewName].map((criteria) => ({
                headerName: criteria,
                field: `${interviewName}_${criteria}`, // Now directly access the prepared rowData fields
                sortable: true,
                filter: true,
                type: 'numericColumn',
                valueGetter: (params) => {
                    const value = parseFloat(params.data[`${interviewName}_${criteria}`]);
                    return !isNaN(value) ? parseFloat(value.toFixed(1)) : parseFloat('0.0'); // Restrict to 1 decimal place
                },
            }));

            columns.push({
                headerName: interviewName,
                children: interviewColumns, // Group the criteria columns under each interview name
            });
        });


        return columns;
    };

    const rowData = applicantReports.map((applicant) => {
        const row = { userName: applicant.userName, userId: applicant.userId };

        // Map each interview and criterion to a unique field in the row
        Object.keys(applicant.interviews).forEach((interviewName) => {
            Object.keys(applicant.interviews[interviewName]).forEach((criteria) => {
                row[`${interviewName}_${criteria}`] = parseFloat(applicant.interviews[interviewName][criteria] || 0);
            });
        });

        return row;
    });

    const exportToCsv = () => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.exportDataAsCsv();
        } else {
            console.warn('Grid API is not ready yet.');
        }
    };
    /*
    // Create Chart Options (Bar chart as an example)
    const chartOptions = {
        data: rowData.map(row => ({ userName: row.userName, totalScore: parseFloat(row.totalScore) })),
        series: [
            {
                type: 'bar',
                xKey: 'userName',
                yKey: 'totalScore',
                yName: 'Total Score',
            },
        ],
        legend: {
            enabled: true,
        },
        axes: [
            {
                type: 'category',
                position: 'bottom',
                label: {
                    rotation: -45
                }
            },
            {
                type: 'number',
                position: 'left'
            }
        ]
    };
    */

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleWeightageTable = () => {
        setShowWeightageTable(prevShow => !prevShow);
    };

    const handleExport = (format) => {
        setDropdownOpen(false);
        switch (format) {
            case 'csv':
                exportToCsv();
                break;
            case 'pdf':
                downloadPDF();
                break;
            case 'excel':
                downloadExcel();
                break;
            default:
                break;
        }
    };
    if (loading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
                <p className="loader-text">Loading applicant reports, please wait...</p>
            </div>
        );
    }

    if (applicantReports.length === 0) {
        return <p>No applicants or no data found for this job.</p>;
    }

    const candidateCount = rowData.length;
    console.log('candidateCount:', candidateCount);

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        };

        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <div className="applicant-reports-container historical-scores-container">
            <header className="dashboard-navbar">
                <div className="poppins-regular">
                    <div>
                        <Link to="/" className="logo" style={{ textDecoration: 'none', marginBottom: '0px', marginTop: '10px' }}>
                            <span className="logo"><span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span></span>
                        </Link>
                    </div>
                    <div>
                        <Link to="/client-dashboard" className="job-listings-nav">
                            Job Listings
                        </Link>
                        &gt;{" "}
                        <Link to={`/client-jobs/${clientJobId}/candidates-list`} className="job-listings-nav">
                            {job.job_title} [All Candidates]
                        </Link>
                        &gt;{" "}
                        Scores
                    </div>

                </div>
                <div className='export poppins-medium'>
                    <button className="btn" onClick={toggleDropdown}>
                        <PiExport style={{ fontSize: '20px', color: '#fff623', alignItems: 'center', justifyContent: 'center' }} />
                        Export
                    </button>
                    {dropdownOpen && (
                        <div className="dropdown-menu">
                            <button onClick={() => handleExport('csv')}><PiFileCsv style={{ fontSize: '20px', color: '#fff623', alignItems: 'center', justifyContent: 'center' }} /> CSV</button>
                            <button onClick={() => handleExport('pdf')}> <PiFilePdfLight style={{ fontSize: '20px', color: '#fff623', alignItems: 'center', justifyContent: 'center' }} /> PDF</button>
                            <button onClick={() => handleExport('excel')}><PiFileXls style={{ fontSize: '20px', color: '#fff623', alignItems: 'center', justifyContent: 'center' }} /> Excel</button>
                        </div>
                    )}
                </div>
            </header>
            <main className="dashboard-main">
                <div className='tag-and-candidates'>
                    <div className='poppins-medium' style={{ display: 'flex' }}>
                        <span className='tag'>Created on: {formatDate(job.createdAt)}</span> {/* Display created date */}
                        <button className='change-weightage-button poppins-medium' onClick={toggleWeightageTable}>Change Weightage</button>
                        <button className="refresh-button poppins-medium" onClick={refreshApplicantReports}>
                            {refreshing ? (
                                <BsArrowRepeat className='spinner-icon' style={{ fontSize: '20px', marginRight: '8px' }} />
                            ) : (
                                <BsArrowRepeat style={{ fontSize: '20px', marginRight: '8px' }} />
                            )}
                            <div>
                                {refreshing ? 'Refreshing...' : 'Refresh Scores'}
                            </div>
                        </button>
                    </div>
                    <div className='candidates poppins-regular'>
                        <h3>Total Candidates Attempted: {candidateCount}</h3>
                    </div>
                </div>
                <div className="ag-theme-quartz-dark poppins-medium" style={{ height: 'fit-content', width: '100%', marginTop: '10px' }}>
                    <AgGridReact
                        ref={gridRef}  // Attach the grid reference
                        rowData={rowData}
                        columnDefs={generateColumns()}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50]}
                        domLayout="autoHeight"
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                            type: 'numericColumn',
                        }}
                    />
                </div>
                <div>
                    {showWeightageTable && (
                        <div>
                            <table className="reports-table poppins-medium">
                                <thead>
                                    <tr>
                                        <th>Interview</th>
                                        <th>Criteria</th>
                                        <th>Weightage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(evaluationCriteriaMap).map(interviewName => (
                                        evaluationCriteriaMap[interviewName].map(criteria => (
                                            <tr key={`${interviewName}-${criteria}`}>
                                                <td>{interviewName}</td>
                                                <td>{criteria}</td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={weightages[interviewName][criteria]}
                                                        onChange={(e) => handleWeightageChange(interviewName, criteria, e.target.value)}
                                                        defaultValue="1"
                                                        min="0"
                                                        step="0.1"
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={saveWeightages} className='save-weightage-btn poppins-medium' style={{ marginTop: '10px' }}>Save Weightages</button>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default JobApplicantReports;
