import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getApiUrl } from '../utils/apiUtils';
import { ClientUserContext } from '../context/ClientUserContext'; // Import ClientUserContext
import { IoIosCloseCircle } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import './CreateQuestions.css';


const CreateQuestions = () => {
    const [error, setError] = useState(null); // New state for error handling
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({}); // New state for error handling

    const [job, setJob] = useState();
    const [interview, setInterview] = useState();
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({
        userQueryText: '',
        questionFormat: '',

        // for uploading questions
        questionText: '',
        questionType: '',
        mediaUrl: '',
        textInstructions: '',
        interviewEvaluationCriteria: '',
        scoringCriteria: 0,
        referenceAnswers: [],
        referenceAnswersQuery: '',
    })
    const [gptResponse, setGptResponse] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [interviewEvaluationCriteria, setInterviewEvaluationCriteria] = useState();
    const [allEvaluationCategories, setAllEvaluationCategories] = useState();
    const [interviewInstructions, setInterviewInstructions] = useState();
    const { clientUser } = useContext(ClientUserContext); // Access client user details from context
    const { jobId, interviewId } = useParams();
    const navigate = useNavigate();
    const formDialogRef = useRef();
    const { clientJobInterviewId } = useLocation().state;

    const QUESTION_FORMATS = [
        ['script only', `[{"question_script": <question>}]`],
        ['question text on video', '[{"question_script": <question>}]'],   
        ['question text with additional ref text', '[{"question_script": <question>, "question_text": <question>}]'],   
        ['question text with additional ref image', '[{"question_script": <question>, "question_image": <image url>}]'],
    ] 
    if (error) {
        console.log("Error: ", error);
    }

    useEffect(() => {
        const getQuestions = async () => {
            try {
                const url = getApiUrl(`/api/interviews/${interviewId}/questions`);
                const res = await fetch(url);
                const data = await res.json();

                if(res.status === 200) {
                    console.log(data);
                    setQuestions(data);
                }
                setLoading(false);

            } catch (error) {
                console.log(error);
            }
        }
        const getJob = async () => {
            try {
                const url = getApiUrl(`/api/client-jobs/${jobId}/`);
                const res = await fetch(url);
                const data = await res.json();

                if(res.status === 200) {
                    console.log(data);
                    setJob(data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        const getInterview = async () => {
            try {
                const url = getApiUrl(`/api/interviews/${interviewId}`);
                const res = await fetch(url);
                const data = await res.json();

                if(res.status === 200) {
                    console.log(data);
                    setInterview(data);
                }
                setLoading(false);

            } catch (error) {
                console.log(error);
            }
        }
        const getInterviewEvaluationCriteria = async () => {
            try {
                const url = getApiUrl(`/api/interview-evaluation-criteria/${clientJobInterviewId}`);
                const res = await fetch(url);
                const criteria = await res.json();

                if(res.status === 200) {
                    console.log(criteria);
                    setInterviewEvaluationCriteria(criteria);
                }

            } catch (error) {
                console.log(error);
            }
        }
        const getAllEvaluationCategories = async () => {
            try {
                const url = getApiUrl(`/api/job-interview-evaluation-categories`);
                const res = await fetch(url);
                const categories = await res.json();

                if(res.status === 200) {
                    console.log(categories);
                    setAllEvaluationCategories(categories.reduce((acc, category) => {
                        acc[category.id] = category
                        return acc;
                    }, {}));
                }

            } catch (error) {
                console.log(error);
            }
        }
        const getInterviewInstructions = async () => {
            try {
                const url = getApiUrl(`/api/interviews/${interviewId}/instructions`);
                const res = await fetch(url);
                const data = await res.json();

                if(res.ok) {
                    setInterviewInstructions(data);
                }

            } catch (error) {
                console.log(error);
            }
        }
        
        getQuestions();
        getJob();
        getInterview();
        getInterviewEvaluationCriteria();
        getAllEvaluationCategories();
        getInterviewInstructions();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        if(name === 'userQueryText') {
            setFormData(prevFormData => ({
                ...prevFormData,
                userQueryText: value
            }))
        } else if(name === 'questionFormat') {
            setFormData(prevFormData => ({
                ...prevFormData,
                questionFormat: value
            }))
        } 
        
        if(name === 'questionText') {
            setFormData(prevFormData => ({
                ...prevFormData,
                questionText: value
            }));
        } else if(name === 'questionType') {
            setFormData(prevFormData => ({
                ...prevFormData,
                questionType: value
            }));
        } else if(name === 'mediaUrl') {
            setFormData(prevFormData => ({
                ...prevFormData,
                mediaUrl: value
            }));
        } else if(name === 'textInstructions') {
            setFormData(prevFormData => ({
                ...prevFormData,
                textInstructions: value
            }));
        } else if(name === 'interviewEvaluationCriteria') {
            setFormData(prevFormData => ({
                ...prevFormData,
                interviewEvaluationCriteria: parseInt(value)
            }));
        } else if(name === 'scoringCriteria') {
            setFormData(prevFormData => ({
                ...prevFormData,
                scoringCriteria: parseInt(value)
            }));
        } else if(name.startsWith('referenceAnswer-')) {
            const idx = parseInt(name.split('-')[1]);
            setFormData(prevFormData => ({
                ...prevFormData,
                referenceAnswers: { ...formData.referenceAnswers, [idx]: value }
            }));
        } else if(name === 'referenceAnswersQuery') {
            setFormData(prevFormData => ({
                ...prevFormData,
                referenceAnswersQuery: value
            }));
        }

        // console.log(formData);
    }

    const handleBack = (e) => {
        e.preventDefault();
        if(currentPage > 1) {
            setCurrentPage(currentPage => currentPage - 1);
            return;
        }
    } 

    const handleNext = (e) => {
        e.preventDefault();
        if(currentPage < 2) {
            setCurrentPage(currentPage => currentPage + 1);
            return;
        } 
        handleCreateQuestion(e);
    }

    const generateReferenceAnswers = async (e) => {
        e.preventDefault();
        const criteria = ['0-1', '1-5'];
        let userQuery = {
            interview_name: interview.interview_name,
            evaluation_criteria: allEvaluationCategories[formData.interviewEvaluationCriteria].description,
            question_text: formData.questionText,
            scoring_criteria: criteria[formData.scoringCriteria],
            text_instructions: formData.textInstructions,
            context: interviewInstructions.context_video_text
        };

        if(formData.referenceAnswersQuery.length !== 0) {
            userQuery.previous_answers = JSON.stringify(formData.referenceAnswers);
            userQuery.previous_answers_updates = formData.referenceAnswersQuery;
        }

        try {
            const res = await fetch(getApiUrl('/api/generate-reference-answers'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userQuery })
            });
            const data = await res.json();
            if(res.ok) {
                const jsonData = JSON.parse(data.answers);
                const answers = jsonData.reduce((acc, x) => {
                    acc[x.score] = x.answer;
                    return acc;
                }, {});
                
                console.log(answers);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    referenceAnswers: answers
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCreateQuestion = async (e) => {
        e.preventDefault();
        console.log(formData);
        try {
            const res = await fetch(getApiUrl('/api/questions'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    client_user_id: 3,
                    question_text: formData.questionText,
                    question_type: formData.questionType,
                    media_url: formData.mediaUrl,
                    text_instructions: formData.textInstructions
                })
            });
            const data = await res.json();        // question id -> data.id
            if(res.ok) {
                const linkRes =  await fetch('/api/interview-questions/link', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        interview_id: interviewId,
                        question_id: data.id,
                        question_order: questions.length + 1
                    })
                });
                const linkData = await linkRes.json();    // interview question id -> linkData.id

                if(linkRes.ok) {
                    console.log('Linked question to the interview');
                    setQuestions(prevQuestions => ([
                        ...prevQuestions,
                        { ...linkData, question: data }
                    ]));

                    for(let score in formData.referenceAnswers) {
                        if(formData.referenceAnswers[score].length === 0) continue;

                        const refRes = await fetch(getApiUrl('/api/job-interview-prompts/reference-answers/'), {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                interview_question_id: linkData.id,
                                evaluation_category_id: formData.interviewEvaluationCriteria,
                                score: score,
                                answer: formData.referenceAnswers[score],
                            })
                        })
                        const refData = await refRes.json();
                        console.log('reference answer created successfully');
                    }
                    formDialogRef.current.close();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    // For generating questions
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = getApiUrl('/api/generate-interview-questions');
            const res = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userQuery: {
                        job_title: job.job_title,
                        job_description: job.job_description,
                        interview_name: interview.interview_name,
                        question_format: formData.questionFormat,
                        text: formData.userQueryText
                    }
                })
            })
            const data = await res.json();
            console.log(data.advice);
            console.log(JSON.parse(data.advice));
            setGptResponse(JSON.parse(data.advice));
        } catch (error) {
            console.log(error);
        }
    }

    if(loading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
                <div className="loader-message">Fetching Interviews...</div>
            </div>
        )
    }

    return (
        <div className="create-questions-container">
            <header className="create-questions-navbar popins-regular">
                <Link to="/" className="logo">
                    <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
                </Link>
                <div>
                    <h2>Welcome, </h2>
                    <h2>{clientUser.user_name}</h2> {/* Display user name from context */}
                </div>
            </header>
            <main className="create-questions-main">
                <div className="poppins-regular">
                    <h2>{ interview && interview.interview_name }</h2>
                    <p id='tag'>{ interview && interview.status }</p>
                </div>
                <div>
                    <button className='primary' onClick={() => formDialogRef.current.showModal()}>Create Question</button>
                </div>
            </main>

            <dialog className='create-question-modal' ref={formDialogRef}>
                <div className='close-button' onClick={() => formDialogRef.current.close()}>
                    <IoClose color='#888' fontSize='1.8rem' />
                </div>
                <div className='progress-status'>
                    <div className={`progress-bar ${currentPage >= 1 ? 'done' : ''}`}></div>
                    <div className={`progress-bar ${currentPage >= 2 ? 'done' : ''}`}></div>
                </div>
                <form className='question-form'>
                    {
                        currentPage === 1 && (<>
                            <div className="form-group textarea">
                                <textarea
                                    name="questionText"
                                    value={formData.questionText}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                <label className={formData.questionText ? 'filled' : ''}>Question Text*</label>
                                {errors.questionText && <p className="error-text">{errors.questionText}</p>}
                            </div>
                            <div className="form-group">
                                <textarea
                                    name="textInstructions"
                                    value={formData.textInstructions}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                                <label className={formData.textInstructions ? 'filled' : ''}>Text Instructions*</label>
                                {errors.textInstructions && <p className="error-text">{errors.textInstructions}</p>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="mediaUrl"
                                    value={formData.mediaUrl}
                                    onChange={handleChange}
                                    required
                                />
                                <label className={formData.mediaUrl ? 'filled' : ''}>Media URL*</label>
                                {errors.mediaUrl && <p className="error-text">{errors.mediaUrl}</p>}
                            </div>
                            <div className="form-group">
                                <select
                                    name="questionType"
                                    value={formData.questionType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value='' disabled>Select Question Type*</option>
                                    <option value='Video'>Video</option>
                                    <option value='Image'>Image</option>
                                </select>
                                {errors.questionType && <p className="error-text">{errors.questionType}</p>}
                            </div>
                        </>)
                    }
                    {
                        currentPage === 2 && (<>
                            <div className="form-group">
                                <select
                                    name="interviewEvaluationCriteria"
                                    value={formData.interviewEvaluationCriteria}
                                    onChange={handleChange}
                                    required
                                >
                                    <option>Select Evaluation Criteria*</option>
                                {
                                    allEvaluationCategories && interviewEvaluationCriteria.length !== 0 && interviewEvaluationCriteria.map(criterion => (
                                        <option key={criterion.id} value={criterion.evaluation_category_id}>{ allEvaluationCategories[criterion.evaluation_category_id].name }</option>
                                    ))
                                }
                                </select>
                                {errors.interviewEvaluationCriteria && <p className="error-text">{errors.interviewEvaluationCriteria}</p>}
                            </div>
                            <div className="form-group">
                                <select
                                    name="scoringCriteria"
                                    value={formData.scoringCriteria}
                                    onChange={handleChange}
                                    required
                                >
                                    <option>Select Scoring Criteria*</option>
                                    <option value='0'>0 - 1</option>
                                    <option value='1'>1 - 5</option>
                                </select>
                                <label className={formData.scoringCriteria ? 'filled' : ''}>Scoring Criteria*</label>
                                {errors.scoringCriteria && <p className="error-text">{errors.scoringCriteria}</p>}
                            </div>

                            { formData.scoringCriteria === 0 && (<>
                                {
                                    [0, 1].map(score => (
                                        <div className='form-group reference-answers'>
                                            <textarea name={`referenceAnswer-${score}`} value={formData.referenceAnswers[score]} onChange={handleChange}></textarea>
                                            <label className={formData.scoringCriteria ? 'filled' : ''}>Score: {score}</label>
                                        </div>
                                    ))
                                }
                            </>)}
                            { formData.scoringCriteria === 1 && (<>
                                    {
                                        [1, 2, 3, 4, 5].map(score => (
                                            <div className='form-group reference-answers'>
                                                <textarea name={`referenceAnswer-${score}`} value={formData.referenceAnswers[score]} onChange={handleChange}></textarea>
                                                <label className={formData.scoringCriteria ? 'filled' : ''}>Score: {score}</label>
                                            </div>
                                        ))
                                    }
                            </>)}

                            { formData.referenceAnswers.length !== 0 && (
                                <div className='form-group reference-answers'>
                                    <textarea name='referenceAnswersQuery' value={formData.referenceAnswersQuery} onChange={handleChange}></textarea>
                                    <label className={formData.referenceAnswersQuery ? 'filled' : ''}>Reference Answers Query</label>
                                </div>
                            )}
                        </>)
                    }
                        
                    <div className='actions'>
                        { currentPage === 2 && <button className='primary generate-ref-answers' onClick={generateReferenceAnswers}>Generate Reference Answers</button>}
                        <button className='secondary back' onClick={handleBack}>Back</button>
                        <button className='primary next' onClick={handleNext}>{ currentPage === 2 ? 'Create' : 'Next'}</button>
                    </div>
                </form>
            </dialog>

            <div className='questions-list-container'>
                <h3 className='poppins-bold'>Interview Questions</h3>
                <ul className="questions-list poppins-regular">
                    {questions
                    ?.sort((a, b) => a.question_order - b.question_order) // Sort questions by question_order
                    .map(question => (
                        <li key={question.id}>
                            <div className='left'>
                                <p><strong>Question {question.question_order}:</strong> {question.question.question_text}</p>
                                <p><strong>Instructions:</strong> {question.question.text_instructions}</p>
                            </div>
                            <div className='right'>
                                {question.question.media_url && (
                                    <iframe
                                        src={question.question.media_url}
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                        title={`Media for Question ${question.question_order}`}
                                        className="iframe-video"
                                    />
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            {/* <main className='generate-questions-form'>
                <h2>Generate Questions</h2>
                <div className='questions-chatbot'>
                    <form onSubmit={ handleSubmit }>
                        <textarea
                            name='userQueryText'
                            value={formData.userQueryText}
                            placeholder='Enter your prompt for generating questions'
                            onChange={handleChange}
                        />
                        <select
                            name='questionFormat'
                            value={formData.questionFormat}
                            onChange={handleChange}
                        >
                            <option disabled={true}>Select a format for questions</option>
                            {
                                QUESTION_FORMATS.map(format => (
                                    <option value={format[1]}>{ format[0] }</option>
                                ))
                            }
                        </select>
                        <button type='submit'>Generate Questions</button>
                    </form>
                </div>
            </main>

            <div className='response-questions'>
            {
                gptResponse && gptResponse.map(question => (
                    <div className='question'>
                        <p>{ question.question_script }</p>
                        <p>{ question.question_text ?? 'null' }</p>
                    </div>
                ))
            }
            </div> */}
        </div>
    );
};

export default CreateQuestions;
