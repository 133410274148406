import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import './VerifyOTP.css';
import signupCircleLarge from '../assets/signup_circle_large.png';
import signupCircleSmall from '../assets/signup_circle_small.png';
import { getApiUrl } from '../utils/apiUtils';

function VerifyOTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { firstName, phoneNumber } = location.state || {};
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendVisible, setResendVisible] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    if (timer === 0) {
      setResendVisible(true);
    }

    return () => clearInterval(interval);
  }, [timer]);


  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(getApiUrl('/auth/verify-otp'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber, otp }),
      });
      const result = await response.json();
      setMessage(result.message);
      setLoading(false);

      if (response.ok) {
        // Save user details in local storage
        localStorage.setItem('user', JSON.stringify(result.user));

        // Set user details in context
        setUser(result.user);
        // Navigate to education form
        navigate('/jobs');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error verifying OTP.');
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (resendCount >= 3) {
      setMessage('You have reached the maximum number of resend attempts. Please try again after 30 minutes.');
      return;
    }

    setResendLoading(true);
    try {
      const response = await fetch(getApiUrl('/auth/resend-otp'), {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber }),
      });
      const result = await response.json();
      setMessage(result.message);
      setResendLoading(false);

      if (response.ok) {
        setResendCount(resendCount + 1);
        setResendVisible(false);
        setTimer(resendCount === 2 ? 1800 : 120); // 30 minutes after 3rd attempt, otherwise 2 minutes
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error resending OTP.');
      setResendLoading(false);
    }
  };

  return (
    <div className="verify-otp-container">
      <header className="verify-otp-navbar">
        <Link to="/" className="logo poppins-regular">
          <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
        </Link>
      </header>
      <h2 className='poppins-medium'>VERIFY OTP</h2>
      <p className='poppins-regular'>Hey {firstName}, please enter the OTP sent to your number: {phoneNumber}</p>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="otp"
          className='poppins-medium'
          value={otp}
          onChange={handleChange}
          placeholder="OTP"
          required
        />
        {loading ? (
          <div className="loader"></div>
        ) : (
          <>
            <button type="submit" className="btn">Verify OTP</button>
            {resendVisible ? (
              <button onClick={handleResendOTP} disabled={resendLoading} className="btn poppins-regular">
                {resendLoading ? 'Resending...' : 'Resend OTP'}
              </button>
            ) : (
              <p className='poppins-regular'>Resend available in {timer} seconds</p>
            )}
          </>
        )}
      </form>
      {message && <p className='poppins-regular'>{message}</p>}
    </div>
  );
}

export default VerifyOTP;