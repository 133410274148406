import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './JobListingPage.css';
import { UserContext } from '../context/UserContext';
import { getApiUrl } from '../utils/apiUtils';
import { useLogout } from '../utils/logout';
import Modal from './Modal'; // Modal component for Contact Us
import { FaUserCircle } from 'react-icons/fa';

const JobListingPage = () => {
    const { user } = useContext(UserContext);
    const [openJobs, setOpenJobs] = useState([]);
    const [eligibleJobs, setEligibleJobs] = useState([]);
    const [appliedJobs, setAppliedJobs] = useState([]);

    const [eligibleJobsList, setEligibleJobsList] = useState([]);
    const [appliedJobsList, setAppliedJobsList] = useState([]);
    const [loading, setLoading] = useState(true); // New loading state
    const [modalOpen, setModalOpen] = useState(false); // Modal state
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const navigate = useNavigate();

    const logout = useLogout(); // for logging out
    console.log(user);

    const fetchOpenJobs = async () => {
        try {
            const response = await fetch(getApiUrl('/api/client-jobs'));
            const data = await response.json();

            setOpenJobs(data.filter(job => job.status === "Open"));    // superset of jobs
        } catch (error) {
            console.error('Error fetching all jobs:', error);
        }
    };

    const fetchUserJobs = async () => {
        try {

            const response = await fetch(getApiUrl(`/api/user-jobs/${user.id}`));
            const data = await response.json();

            // below logic basically maps job_id -> job (if status is Eligible / Applied)
            // so that, from the superset of openJobs, we can simply lookup its existence in these maps
            setEligibleJobs(data.reduce((acc, userJob) => {
                if (userJob.status === "Eligible") acc[userJob.job_id] = userJob;
                return acc;
            }, {}));
            setAppliedJobs(data.reduce((acc, userJob) => {
                if (userJob.status === "Applied") acc[userJob.job_id] = userJob;
                return acc;
            }, {}));
        } catch (error) {
            console.error('Error fetching user jobs:', error);
        }
    };

    const loadJobs = async () => {
        await fetchUserJobs();                  // first fetching the user jobs, for filtering the openJobs
    };

    useEffect(() => {
        // filtering the Eligible and Applied jobs from a list of Open Jobs
        if (appliedJobs) {
            fetchOpenJobs().then(() => {
                setEligibleJobsList(openJobs.reduce((acc, job) => {
                    if (eligibleJobs[job.id]?.status === "Eligible") {
                        acc.push({ ...eligibleJobs[job.id] });
                    }
                    return acc;
                }, []));
                setAppliedJobsList(openJobs.reduce((acc, job) => {
                    if (appliedJobs[job.id]?.status === "Applied") {
                        acc.push({ ...appliedJobs[job.id] });
                    }
                    return acc;
                }, []));
                setLoading(false);
            }).catch(error => console.log(error));
        }
    }, [appliedJobs]);

    useEffect(() => {
        if (!user)
            navigate("/login");
        loadJobs();
    }, [user?.id]);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };


    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        };

        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <div className="job-listing-page">
            <header className="signup-navbar">
                <div className="left">
                    <div>
                        <span className="logo"><span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span></span>
                    </div>
                    <div className="credentials">
                        {/* <div className='desktop-user-info joblisting-right'>
                        <div className='desktop-user-info welcome-name'>
                            <div className="poppins-semibold" style={{ color: '#777', margin: '0px', padding: '0px' }}>Welcome!</div>
                            <h2 style={{ margin: '0px', padding: '0px' }}>{user?.firstName} {user?.lastName}</h2>
                        </div>
                        <div className="logout-button">
                            <button className="poppins-regular button-theme" onClick={logout}>Logout</button>
                        </div>
                    </div> */}
                        <div className="mobile-user-info">
                            <FaUserCircle className="user-icon" onClick={toggleUserMenu} />
                            {isUserMenuOpen && (
                                <div className="interviewhr-dropdown-menu">
                                    <div className='poppins-regular'>{user.firstName} {user.lastName}</div>
                                    <button className="button-theme poppins-regular" onClick={logout}>Logout</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </header>

            {loading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                    <p className="loader-text">Loading jobs, please wait...</p>
                </div>
            ) : (
                <>
                    {/* Applied Jobs Section */}
                    {appliedJobsList.length > 0 && (
                        <div>
                            <h2 className="poppins-medium">Applied Jobs</h2>
                            <ul className="job-list">
                                {appliedJobsList.map(job => (
                                    <li key={job.id} className="job-item poppins-regular">
                                        <div className='job-item-container'>
                                            <div className='job-title-container'>
                                                <h3 className='job-title'>{job.job.job_title}</h3>
                                                <div className='poppins-regular tag-container'>
                                                    <div className='tag poppins-light'>Applied on: {formatDate(job.updatedAt)}</div> {/* Display created date */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='button-container'>
                                            <button
                                                onClick={() => navigate(`/interviews/${job.job_id}`)}
                                                className="apply-button poppins-regular"
                                                disabled={false}
                                            >
                                                Go to Interviews
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {eligibleJobsList.length > 0 && (
                        <div>
                            <h2 className="poppins-medium">Available Jobs</h2>
                            <ul className="job-list">
                                {eligibleJobsList.map(job => (
                                    <li key={job.id} className="job-item poppins-regular">
                                        <div className='job-item-container'>
                                            <div className='job-title-container'>
                                                <h3 className='job-title'>{job.job.job_title}</h3>
                                            </div>
                                        </div>
                                        <div className='button-container'>
                                            <button
                                                onClick={() => navigate(`/job-details/${job.job_id}`, { state: { userJobId: job.id } })}
                                                className="apply-button poppins-regular"
                                            >
                                                View Job Details
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    {appliedJobsList.length === 0 && eligibleJobsList.length === 0 && !loading && (
                        <div className="no-jobs-message poppins-semibold centered">
                            <p>You have not been invited to any jobs. Please contact us for any queries.</p>
                            <button className="contact-btn poppins-medium" onClick={toggleModal}>Contact Us</button>
                        </div>
                    )}
                    {/* Modal for Contact Us */}
                    {modalOpen && (
                        <Modal
                            toggleModal={toggleModal}
                            headerText="Contact Us"
                            bodyText="Please reach out to us at: kunal@pehchaan.me"
                            buttonText="Close"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default JobListingPage;