import React, { useState, useContext, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { getApiUrl } from '../utils/apiUtils';
import { useDropzone } from 'react-dropzone';
import './SignUp.css'; // Reusing SignUp.css for consistent styling
import signupCircleLarge from '../assets/signup_circle_large.png';
import signupCircleSmall from '../assets/signup_circle_small.png';
import avatarImage from '../assets/avatar_image.png';
import io from 'socket.io-client';

const CvUploadForm = () => {
    const { user } = useContext(UserContext);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // Establish Socket.IO connection
    const socket = io(getApiUrl('/'));

    const onDrop = useCallback((acceptedFiles) => {
      setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!user) {
        alert('User is not set. Please log in again.');
        navigate('/login');
        return;
      }

      setLoading(true);

      const formData = new FormData();
      formData.append('user_id', user.id);
      formData.append('socketId', socket.id); // Include the socket ID
      if (file) {
        formData.append('cv', file);
      }

      try {
        const response = await fetch(getApiUrl('/api/upload-cv'), {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('Server Response:', responseData);
        // Clear the form for the next entry
        setFile(null);

        // Navigate to optional sections after successful submission
        navigate('/cv-upload-progress');
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    return (
        <div className="signup-container">
        <header className="signup-navbar">
          <div className="left">
            <Link to="/" className="logo">
              <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
            </Link>
          </div>
        </header>
        <div className="circle-large">
          <img src={signupCircleLarge} alt="Large Circle" />
        </div>
        <div className="circle-small">
          <img src={signupCircleSmall} alt="Small Circle" />
        </div>
        <div className="signup-main-content">
          <h2>UPLOAD YOUR CV</h2>
          <div className="greeting-container">
            <img src={avatarImage} alt="Person" className="greeting-image" />
            <div className="greeting-bubble">Please upload your CV for processing.</div>
          </div>
        </div>
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
            <div className="loader-message">Uploading your CV...</div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="cv-upload-form">
            <div 
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '2px dashed #C28753',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                marginBottom: '20px',
                cursor: 'pointer',
              }}
            >
            <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop a CV file here, or click to select one</p>
              )}
              {file && (
              <p style={{ marginTop: '10px', color: '#C28753' }}>
                Selected file: {file.name}
              </p>
              )}
            </div>
          <button type="submit" className="btn">Upload CV</button>
        </form>
        )}
      </div>
    );
};

export default CvUploadForm;