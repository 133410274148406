import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './VerifyOTP.css';
import { ClientUserContext } from '../context/ClientUserContext';
import { getApiUrl } from '../utils/apiUtils';

const ClientVerifyOTP = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setClientUser } = useContext(ClientUserContext); // Use the client user context
    const { phoneNumber, email } = location.state || {};
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [resendVisible, setResendVisible] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const [timer, setTimer] = useState(120);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        if (timer === 0) {
            setResendVisible(true);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const handleChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(getApiUrl('/auth/client/verify-otp'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, email, otp }),
            });
            const result = await response.json();
            setMessage(result.message);
            setLoading(false);

            if (response.ok) {
                // Save client user details in localStorage
                localStorage.setItem('clientUser', JSON.stringify(result.clientUser));

                // Set client user details in context
                setClientUser(result.clientUser);

                // Navigate to client dashboard
                navigate('/client-dashboard');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Error verifying OTP.');
            setLoading(false);
        }
    };

    const handleResendOTP = async () => {
        if (resendCount >= 3) {
            setMessage('You have reached the maximum number of resend attempts. Please try again after 30 minutes.');
            return;
        }

        setResendLoading(true);
        try {
            const response = await fetch(getApiUrl('/auth/client/resend-otp'), {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, email }),
            });
            const result = await response.json();
            setMessage(result.message);
            setResendLoading(false);

            if (response.ok) {
                setResendCount(resendCount + 1);
                setResendVisible(false);
                setTimer(resendCount === 2 ? 1800 : 120); // 30 minutes after 3rd attempt, otherwise 2 minutes
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Error resending OTP.');
            setResendLoading(false);
        }
    };

    return (
        <div className="verify-otp-container poppins-regular">
            <header className="verify-otp-navbar">
                <Link to="/" className="logo poppins-regular">
                    <span className="logo-peh">Peh</span><span className="logo-chaan">chaan</span>
                </Link>
            </header>
            <h2>VERIFY OTP</h2>
            <p>Hi there, please enter the OTP sent to your phone number: {phoneNumber}</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="otp"
                    value={otp}
                    onChange={handleChange}
                    placeholder="OTP"
                    required
                />
                {loading ? (
                    <div className="loader"></div>
                ) : (
                    <>
                        <button type="submit" className="btn">Verify OTP</button>
                        {resendVisible ? (
                            <button onClick={handleResendOTP} disabled={resendLoading} className="btn">
                                {resendLoading ? 'Resending...' : 'Resend OTP'}
                            </button>
                        ) : (
                            <p>Resend available in {timer} seconds</p>
                        )}
                    </>
                )}
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ClientVerifyOTP;
